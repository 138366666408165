<template>
  <layout :loading="loading">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <b-button
                variant="secondary"
                class="icon-button back-button"
                @click="goToProducts"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <h1 class="title-1">Product Categories</h1>
      </div>
      <b-button
              class="mr-2"
              variant="success"
              @click="showModalForm"
      >
        <div class="d-flex align-items-center">
          <i class="bx bx-plus font-size-18 mr-2" />
          Add Category
        </div>
      </b-button>
    </div>
    <b-tabs>
      <b-tab title="Categories" active></b-tab>
      <b-tab title="Platforms" @click="goToPlatforms"></b-tab>
    </b-tabs>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-10">
                <div class="search-block">
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="col row">
                <div class="table-responsive">
                  <b-table head-variant="light" hover :items="categories" :fields="tableFields">
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        style="margin-top: -0.5rem;"
                        variant="outline-warning"
                        class="shadow-none border-0 p-2 mt--2"
                        @click="editHandler(data.item)"
                      >
                        <span class="fa fa-pen"></span>
                      </b-button>
                      <b-button
                        style="margin-top: -0.5rem;"
                        variant="outline-danger"
                        class="shadow-none border-0 p-2 mt--2"
                        @click="onDeleted(data.item.id)"
                      >
                        <span class="fa fa-trash"></span>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormModal :modalTitle="modalTitle" :buttonText="buttonText" :modalId="modalId" :modalData="modalData" @success="successHandler" />
  </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {convertObjectToFormData} from '@/utils/converters'
import FormModal from '@/components/products/categories-modal.vue'

export default {
  components: {
    FormModal
  },
  data() {
    return {
      title: 'Categories',
      tableFields: [
        {
          key: 'index',
          label: '#',
          class: 'column-number',
        },
        {
          key: 'title',
          sortable: true,
        },
        {
          key: 'description',
          sortable: true,
        },
        {
          key: 'actions',
          class: 'cell-action'
        }
      ],
      searchValue: '',
      optionsEmployee: [],
      employeeSelected: [],
      tagsSelected: [],
      modalId: null,
      modalTitle: 'Create new category',
      buttonText: 'Create',
      modalData: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories/getCategories',
    }),
  },
  async mounted() {
    this.loading = true;
    await this.newSearch()
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchCategories: 'categories/fetchCategories',
      deleteCategory: 'categories/deleteCategory',
    }),
    async newSearch() {
      await this.fetchCategories()
    },
    async onDeleted (id) {
      await this.deleteCategory({
        id,
        formData: convertObjectToFormData({_method: 'DELETE'})
      })
      await this.newSearch()
    },
    showModalForm () {
      this.modalTitle = 'Create new category'
      this.buttonText = 'Create'
      this.modalId = 'modal-category-new'
      this.modalData = null
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    editHandler (item) {
      this.modalTitle = 'Edit category'
      this.buttonText = 'Save'
      this.modalId = `modal-category-${item.id}`
      this.modalData = item
      setTimeout(() => {
        this.$bvModal.show(this.modalId)
      }, 10)
    },
    successHandler () {
      this.newSearch()
      this.$bvModal.hide(this.modalId)
    },
    goToProducts() {
      this.$router.push({ name: 'manage-products' });
    },
    goToPlatforms() {
      this.$router.push({ name: 'platforms' });
    }
  },
}
</script>

<style>
.cell-action {
  width: 115px;
  text-align: right;
}
.column-number {
  width: 50px;
}
.table th, .table td {
  vertical-align: baseline;
}
</style>
