<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    hide-footer
    centered
    scrollable
    title-class="font-18"
    ref="modalBoard"
    @hidden="handleCloseModal"
    @shown="setData"
  >
    <b-form
      class="purchase-form"
      @submit="handleSubmit"
    >
      <b-form-group
        label="Title"
        label-cols-md="3"
        label-align="right"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="form.title"
          type="text"
          required
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-md="3"
        label-align="right"
        label-for="description"
      >
        <b-form-textarea
          id="description"
          v-model="form.description"
          required
        />
      </b-form-group>
      <b-form-group
              label="Images Ratio"
              label-cols-md="3"
              label-align="right"
              label-for="image_ratio"
      >
          <b-form-select id="image_ratio" v-model="form.ratio">
              <b-form-select-option value="1">1:1</b-form-select-option>
              <b-form-select-option value="2">16:9</b-form-select-option>
          </b-form-select>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { validateForm } from '@/utils/validation'
import { convertObjectToFormData } from '@/utils/converters'
export default {
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    modalData: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      form: {
        title: '',
        description: '',
        ratio: 1
      }
    }
  },
  methods: {
    setData () {
      if (this.modalData) {
        this.form.title = this.modalData.title
        this.form.description = this.modalData.description
        this.form.ratio = this.modalData.ratio
      }
    },
    handleCloseModal () {
      this.form = {
        title: '',
        description: '',
        ratio: ''
      }
    },
    async handleSubmit (event) {
      event.preventDefault()
      let isSuccess = true
      if (validateForm(this.form)) {
        if (!this.modalData) {
          await this.$store.dispatch('categories/createCategory', convertObjectToFormData(this.form))
           .then((response) => {
             if (response.code != 200) {
               isSuccess = false
             }
           })
        } else {
          this.form['_method'] = 'PUT'
          await this.$store.dispatch('categories/updateCategory', {
            id: this.modalData.id,
            formData: convertObjectToFormData(this.form)
          })
            .then((response) => {
              if (response.code != 200) {
                isSuccess = false
              }
            })
        }
        if (isSuccess) {
          this.$emit('success')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>